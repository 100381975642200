import React, {useEffect, useState} from 'react';
import {BigNumber, Contract} from "ethers";


function useBalance(contract: Contract, account: string) {
    const [balance, setBalance] = useState<BigNumber>()

    useEffect(() => {
        const loadBalance = async () => {
            const balance = await contract.balanceOf(account);
            setBalance(balance)
        }

        if (contract && account) {
            loadBalance().then()
        }
    }, [contract, account]);

    return balance
}

export default useBalance;
