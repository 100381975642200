import {BigNumber, constants, Contract} from "ethers";
import { getGasPrice } from "./eth";

export const checkAllowance = async (owner: string, address: string, tokenContract: Contract) => {
    const allowance: BigNumber = await tokenContract.allowance(owner, address);

    if (allowance.eq(0)) {
        const gasPrice = await getGasPrice();
        const tx = await tokenContract.approve(address, constants.MaxUint256, {gasPrice});
        await tx.wait();
    }
};
