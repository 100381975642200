import "./App.css";
import React, {useEffect, useState} from "react";
import {RootState} from "./store";
import {useDispatch, useSelector} from "react-redux";
import ConnectWallet from "./Button/ConnectWallet";
import useBalance from "./hooks/useBalance";
import {getContracts} from "./utils/eth";
import formatBalance from "./utils/formatBalance";
import {checkAllowance} from "./utils/configureAllowance";



function App() {
  const system = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const balance = useBalance(getContracts().fnc, system.account);

  const bridgeTokens = async () => {
    await checkAllowance(
      system.account,
      getContracts().bridge.address,
      getContracts().fnc
    );

    const tx = await getContracts().bridge.bridgeTokens();

    await tx.wait();
  }

  return (
      <div className="App">
        <ConnectWallet deviceType={"desktop"} />
        {system.account && <div>
          <div className="text">Balance: {balance ? formatBalance(balance) : 0} FNC</div>
          <button className="button" onClick={bridgeTokens}>Bridge tokens to polygon</button>
        </div>}
      </div>
  );
}

export default App;
