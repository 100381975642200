import { utils, Contract, providers, Signer, VoidSigner } from 'ethers';
import * as abi from '../abi';

let signer: Signer = new VoidSigner('');
export const getSigner = () => signer;

const isTest = false;

const contracts = isTest ? {
  bridge: new Contract('0x21109f9A144e1afca091F45912f4a589fFDCE940', abi.bridge),
  fnc: new Contract('0x8f0Efc2d044b59046C98a405780FDFEA76A50e98', abi.fncAbi),
} : {
  bridge: new Contract('0xdf85f1abb126e639814ec79b3064072f8C510bc4', abi.bridge),
  fnc: new Contract('0x7f280daC515121DcdA3EaC69eB4C13a52392CACE', abi.fncAbi),
};

export const getContracts = () => contracts;

interface MetaMaskProvider extends providers.ExternalProvider {
  on?(event: string, handler: () => void): void;
}

interface MetaMaskWindow extends Window {
  ethereum?: MetaMaskProvider;
}

function getInjectedProvider() {
  const win = window as MetaMaskWindow;
  if (!win.ethereum) throw new Error('No MetaMask');
  return win.ethereum as MetaMaskProvider;
}

function initGlobals(provider: providers.Web3Provider) {
  signer = provider.getSigner();
  for (const x of Object.keys(contracts)) {
    const key = x as keyof typeof contracts;
    contracts[key] = contracts[key].connect(signer);
  }
}

async function switchNetwork() {
  const injected = getInjectedProvider();
  const isTestNetwork = isTest;
  const chainId = utils.hexStripZeros(utils.hexlify(isTestNetwork ? 80001 : 56));

  try {
    await injected.request?.({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }],
    });
  } catch (err: any) {
    if (isTestNetwork) {
      await injected.request?.({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: chainId,
            chainName: 'Mumbai TestNet',
            rpcUrls: ['https://rpc-mumbai.matic.today'],
            blockExplorerUrls: ['https://mumbai.polygonscan.com'],
            nativeCurrency: {
              name: 'Polygon',
              symbol: 'MATIC',
              decimals: 18,
            },
          },
        ],
      });
    } else {
      await injected.request?.({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: chainId,
            chainName: 'Smart Chain',
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com'],
            nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18,
            },
          },
        ],
      });
    }
  }
}

async function requestPermissions() {
  const injected = getInjectedProvider();
  try {
    await injected.request?.({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
  } catch (e) {
  }
}

async function init(provider: providers.Web3Provider) {
  await switchNetwork();
  initGlobals(provider);

  const injected = getInjectedProvider();
  injected.on?.('chainChanged', () => switchNetwork());
  injected.on?.('accountsChanged', () => window.location.reload());
}
export const getProvider = () => {
  return new providers.Web3Provider(getInjectedProvider());
}

export const getGasPrice = async () => {
  return (await getProvider().getGasPrice()).add('30000000000')
}

export const connect = async () => {
  const provider = new providers.Web3Provider(getInjectedProvider());
  const accounts = await provider.listAccounts();

  if (accounts.length > 0) {
    await init(provider);
    return { account: accounts[0] };
  }
  return { account: '' };
}

export const login = async () => {
  const injected = getInjectedProvider();

  await requestPermissions();
  const accounts = await injected.request?.({ method: 'eth_requestAccounts' });

  await init(new providers.Web3Provider(injected));

  return { account: accounts[0] as string };
};
