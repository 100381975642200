import {GameState} from '../types/systemState';
import * as actionTypes from '../types/actionTypes';

export const initialState: GameState = {
};

export function systemReducer(state = initialState, action): GameState {
  switch (action.type) {
    case actionTypes.SET_SIGNATURE: {
      return {
        ...state,
        account: action.account
      };
    }
    default:
      return state;
  }
}
