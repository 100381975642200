import React, {useCallback, useEffect, useState} from 'react'
import {truncateEthAddress, truncateEthAddressMobile} from "../utils/truncate";
import useAppSelector from "../hooks/useAppSelector";
import useAppDispatch from "../hooks/useAppDispatch";
import {login} from "../utils/eth";
import {setSignature} from "../store/actions/actionsSystem";

interface ConnectWalletProps {
    deviceType: 'desktop' | 'mobile'
}

function ConnectWallet({ deviceType }: ConnectWalletProps) {
    const [open, setOpen] = React.useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const account = useAppSelector((state) => state.account);
    const dispatch = useAppDispatch();

    const handleConnect = useCallback((ev) => {
        setIsLogin(true);
        login().then((obj) => dispatch(setSignature(obj.account)));
    }, []);

    // const handleConnectWallet = (connectorName: string) => () => {
    //     handleConnect()
    //     setOpen(false);
    // };

    const handleClickOpen = () => {
        setOpen(true);
    };


    return (
        <>
            {account ?
                <span className="text" onClick={handleClickOpen}>
                    {
                        deviceType === 'desktop' ?
                            truncateEthAddress(account) :
                            truncateEthAddressMobile(account)
                    }
                </span> :
                    <button className="button" onClick={handleConnect}>
                        Connect
                    </button>
            }
        </>
    )
}

export default ConnectWallet
