import { applyMiddleware, createStore } from 'redux';
import { systemReducer } from './reducers/reducersSystem';
import { save, load } from 'redux-localstorage-simple';
import {composeWithDevTools} from 'redux-devtools-extension';
import {GameState} from './types/systemState';

const rootReducer = systemReducer;
export type RootState = ReturnType<typeof rootReducer>;

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(
    save()
  )
)(createStore);

const loadedState = load() as GameState;

export const store = createStoreWithMiddleware(
  rootReducer,
  {
    account: '',
  } as GameState
);
export type AppDispatch = typeof store.dispatch;
