// Captures 0x + 4 characters, then the last 4 characters.
const truncateRegex = /^(0x)[a-zA-Z0-9]+([a-zA-Z0-9]{8})$/;

/**
 * Truncates an ethereum address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
export const truncateEthAddress = (address: string) => {
  const match = address.match(truncateRegex);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateEthAddressMobile = (address: string) => {
  return `${address.slice(0, 4)}…${address.slice(address.length - 2, address.length)}`
}
