import {
  SET_SIGNATURE,
} from '../types/actionTypes';

export function setSignature(account?: string) {
    return {
        type: SET_SIGNATURE,
        account
    };
}
